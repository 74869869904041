@import './Styles/Base.scss';

.app-wrapper {
  padding: 0 10px;
  height: 100%;
  width: 375px;
}

@media only screen and (max-width: 380px) {
  .app-wrapper {
    width: 100%;
  }
}
