.form-head-wrapper {
  padding: 20px 0 0 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9999;
  .form-head {
    display: flex;
    justify-content: space-between;
    & > a {
      display: flex;
      padding-top: 5px;
      .form-head-back-btn {
        cursor: pointer;
        color: #000;
        opacity: 0.6;
      }
    }
    .form-head-title {
      font-weight: bold;
      font-size: 24px;
      color: #000;
      margin: 0 0 0 -16px;
    }
  }
  .form-progress-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .form-progress {
      width: 70%;
      .ant-progress-outer {
        .ant-progress-inner {
          background-color: #c2d0da;
          border-radius: 0;
          .ant-progress-bg {
            border-radius: 0;
            background-color: #5cc5e6;
            height: 4px !important;
          }
        }
      }
    }
  }
}
