.choose-form-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-top: 15px;
  .choose-form-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    .help_icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .logout-btn {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #000;
      text-decoration: none;
    }
    .logout-btn-wrapper {
      width: 114px !important;
      overflow: hidden;
      padding: 0;
      width: 100%;
      height: 100%;
    }
    .fake-logout-btn {
      background-color: #fff;
      width: 120px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      pointer-events: none;
      cursor: pointer;
      z-index: 999;
      color: #000;
      font-size: 13px;
    }
  }
  .choose-form-title {
    font-weight: 500;
    font-size: 24px;
    color: #000;
    margin-bottom: 30px;
    white-space: pre-line;
  }
  .choose-form-btn-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    .choose-form-btn-container {
      width: 80%;
      margin-bottom: 25px;
      .choose-form-btn {
        min-width: 160px;
        white-space: normal;
        height: 100px;
        border: 1px solid #127bac;
        border-radius: 4px;
        background-color: #fff;
        text-decoration: none;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        &:hover {
          text-decoration: none;
        }
      }
      .choose-form-btn-description {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.4px;
        color: #000;
        margin: 0;
        white-space: pre-line;
      }
    }
  }
}
