.PatientInfo-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  .PatientInfo-form-title {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #000;
  }
  .save-profile-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .save-profile-btn {
      width: 135px;
      height: 36px;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #000;
      background: #27ade1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      margin-bottom: 10px;
      margin-top: 10px;
      &:hover {
        text-decoration: none;
      }
    }
    .disabled-save-profile-btn {
      width: 135px;
      height: 36px;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #008ac2;
      background: #fff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      margin-bottom: 10px;
      margin-top: 10px;
      cursor: not-allowed;
      border: 1px solid #d9d9d9;
    }
  }
}
