.form-description-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 40px;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    letter-spacing: 0.18px;
    color: #000;
    padding-top: 20px;
    white-space: pre-line;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000;
    padding-top: 20px;
    white-space: pre-line;
  }
  .get-started-link {
    height: 36px;
    width: 132px;
    background: #27ade1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-top: 150px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #000;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}
