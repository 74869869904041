.submit-success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  .submit-success-icon {
    width: 90px;
    height: 90px;
    color: #000;
    margin-bottom: 20px;
  }
  p {
    font-weight: normal;
    font-size: 20px;
    color: #000;
    white-space: pre-line;
  }
  .back-home-btn {
    height: 36px;
    background-color: #27ade1;
    color: #000;
    text-transform: uppercase;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    padding: 0 10px;
    text-decoration: none;
  }
}
