.hsper-form-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  padding: 30px 0 0 0;
  height: 74%;
  .hesper-question-wrapper {
    margin-bottom: 10px;
    .hesper-question-title {
      font-weight: bold;
      font-size: 24px;
      color: #000;
    }
    .hesper-question {
      font-weight: normal;
      font-size: 20px;
      color: #000;
    }
    .hesper-answers-radio-btn {
      width: 100%;
      display: flex;
      flex-direction: column;
      .ant-radio-wrapper {
        text-align: left;
        background-color: #fff !important;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 10px;
        margin: 0 0 5px 0;
        .ant-radio {
          .ant-radio-inner {
            border: 1px solid #d9d9d9;
          }
        }
        &.ant-radio-wrapper-checked {
          .ant-radio {
            .ant-radio-inner {
              border: 1px solid #008ac2;
              &::after {
                background-color: #008ac2;
              }
            }
          }
        }
      }
    }
    .steps-content {
      padding: 0 0 20px 0;
      white-space: pre-line;
    }
  }
  .steps-action {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 20px 0;
    .hesper-next-btn {
      width: 90px;
      height: 36px;
      background-color: #27ade1;
      color: #000;
      text-transform: uppercase;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border-radius: 4px;
      font-size: 14px;
    }
    .hesper-next-btn-disabled {
      width: 90px;
      height: 36px;
      background-color: #ffff !important;
      color: #008ac2;
      text-transform: uppercase;
      border: 1px solid #d9d9d9;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border-radius: 4px;
      font-size: 14px;
      &:hover {
        color: #008ac2;
        opacity: 1;
      }
    }
  }
}
.hesper-submit-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  background-color: #fff;
  z-index: 9999;
  a {
    text-decoration: none;
  }
  .hesper-submit-btn {
    width: 90px;
    height: 36px;
    background-color: #27ade1;
    color: #000;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      text-decoration: none;
      background-color: #27ade1;
      color: #000;
    }
  }
}

.fadeIn_animated {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
