.date-picker-wrapper {
  margin-bottom: 20px;
  .date-picker-title {
    font-weight: normal;
    font-size: 20px;
    color: #000;
  }
  .ant-picker {
    width: 100%;
    background-color: #e5e5e5;
    .ant-picker-input {
      input {
        &::placeholder {
          color: #000;
        }
      }
      .ant-picker-suffix {
        color: #000;
      }
    }
    .ant-picker-clear {
      .anticon {
        background-color: #e5e5e5;
      }
    }
  }
}

.ant-picker-dropdown {
  width: 355px;
  color: #000;
  .ant-picker-panel {
    width: 100%;
    .ant-picker-month-panel,
    .ant-picker-year-panel,
    .ant-picker-decade-panel {
      width: 100%;
    }
    .ant-picker-date-panel {
      width: 100%;
      .ant-picker-header {
        .ant-picker-header-super-prev-btn,
        .ant-picker-super-prev-icon,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn {
          color: #000;
        }
      }
      .ant-picker-content {
        width: 100%;
        thead {
          display: none;
        }
        tbody {
          .ant-picker-cell {
            .ant-picker-cell-inner {
              background-color: #e5e5e5;
              font-weight: normal;
              font-size: 14px;
              color: #191919;
            }
          }
        }
      }
    }
    .ant-picker-footer {
      display: none;
    }
  }
}

.form-dropdown-wrapper {
  padding-bottom: 20px;
  .form-dropdown-title {
    font-weight: normal;
    font-size: 20px;
    color: #000;
  }
  .form-dropdown {
    width: 100%;
    .ant-select-selector {
      background-color: #e5e5e5 !important;
    }
    .ant-select-arrow {
      color: #000;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .form-dropdown-arrow {
      pointer-events: none !important;
    }
    .ant-select-selection-placeholder {
      font-size: 14px;
      color: #000;
    }
  }
}

.ant-select-dropdown {
  .rc-virtual-list-holder {
    height: 122px;
    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-bottom-right-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #979797;
      border-radius: 4px;
    }
    &::-ms-scrollbar {
      height: 4px;
      width: 4px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
      border-bottom-right-radius: 4px;
    }
    &::-ms-scrollbar-thumb {
      background: #979797;
      border-radius: 4px;
    }
    &::-moz-scrollbar {
      height: 4px;
      width: 4px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
      border-bottom-right-radius: 4px;
    }
    &::-moz-scrollbar-thumb {
      background: #979797;
      border-radius: 4px;
    }
  }
}

.present-session-wrapper {
  .present-session-title {
    font-weight: normal;
    font-size: 18px;
    color: #000;
    display: flex;
    align-items: flex-start;
    span {
      margin: 4px 0 0 10px;
      color: #c4c4c4;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      opacity: 0.6;
    }
  }
  .present-session-checkbox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding-bottom: 20px;
    .ant-checkbox-wrapper {
      margin: 0;
      display: flex;
      align-items: center;
      .ant-checkbox {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
      }
      .ant-checkbox-checked {
        border: 1px solid #008ac2;
        border-radius: 2px;
        .ant-checkbox-inner {
          background-color: #008ac2;
          border-radius: 0;
        }
      }
      span {
        font-weight: normal;
        font-size: 16x;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}

.setting-wrapper {
  padding-bottom: 20px;
  .setting-title {
    font-weight: normal;
    font-size: 20px;
    color: #000;
    display: flex;
    align-items: flex-start;
    span {
      margin: 4px 0 0 10px;
      color: #c4c4c4;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      opacity: 0.6;
    }
  }
  .ant-input-textarea {
    textarea {
      background-color: #fff;
      text-overflow: ellipsis;
      color: #000;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      &::placeholder {
        font-size: 12px;
        color: #d9d9d9;
        padding-top: 2px;
      }
      &:focus {
        border: 1px solid #d9d9d9;
        outline: none;
        box-shadow: none;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .ant-picker-dropdown {
    width: calc(100% - 20px);
  }
}
