.hsper-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  .hesper-question-wrapper {
    margin-bottom: 20px;
    width: 100%;
    .hesper-question-title {
      font-weight: bold;
      font-size: 24px;
      color: #000;
    }
    .hesper-question {
      font-weight: normal;
      font-size: 20px;
      color: #000;
    }
    .hesper-answers-radio-btn {
      width: 100%;
      display: flex;
      flex-direction: column;
      .ant-radio-wrapper {
        text-align: left;
        padding: 4px 10px;
        margin: 0 0 5px 0;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .hesper-question-wrapper {
    text-align: left;
  }
}
