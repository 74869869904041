.contact-us-modal-open {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0.5px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  background-color: transparent;
  overflow: hidden;
}

.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(215, 215, 215, 0.8);
  }
  .contact-us-modal {
    .ant-modal {
      top: 165px;
      .ant-modal-content {
        border-radius: 3.5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 0.5px solid #127bac;
        background-color: #fff;
        .ant-modal-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
        }
      }
    }
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      color: #000;
      text-transform: uppercase;
      margin: 20px 0 16px 0;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #000;
      margin-bottom: 16px;
      white-space: pre-line;
    }
    .contact-us-text-area {
      width: 100%;
      height: 125px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 3.5px;
      resize: none;
      font-size: 14px;
      color: #000;
      overflow-y: auto;
      text-overflow: ellipsis;
      &::placeholder {
        font-size: 14px;
        color: #000;
        opacity: 0.5;
      }
      &:focus {
        border: 1px solid transparent;
      }
      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-bottom-right-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #979797;
        border-radius: 4px;
      }
      &::-ms-scrollbar {
        height: 4px;
        width: 4px;
      }
      &::-ms-scrollbar-track {
        background-color: transparent;
        border-bottom-right-radius: 4px;
      }
      &::-ms-scrollbar-thumb {
        background: #979797;
        border-radius: 4px;
      }
      &::-moz-scrollbar {
        height: 4px;
        width: 4px;
      }
      &::-moz-scrollbar-track {
        background-color: transparent;
        border-bottom-right-radius: 4px;
      }
      &::-moz-scrollbar-thumb {
        background: #979797;
        border-radius: 4px;
      }
    }
    .contact-us-send-btn-wrapper {
      width: 67px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      .contact-us-send-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 100%;
        height: 100%;
        text-transform: uppercase;
        color: #000;
        background-color: #27ade1;
        border: none;
        border-radius: 4px;
        transition: all 0.3s ease-in-out;
        border: 1px solid transparent;
        &.disabled {
          border: 1px solid rgba(0, 0, 0, 0.12);
          color: #27ade1;
          background-color: #fff;
          cursor: not-allowed;
        }
      }
    }
  }
}
