.pfa-form-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .steps-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    .pfa-form-action {
      width: 90px;
      height: 36px;
      background-color: #27ade1;
      color: #000;
      text-transform: uppercase;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-size: 14px;
      margin: 20px 10px 20px 0;
      &.pfa-form-action-white {
        background-color: #fff;
        color: #008ac2;
        border: 1px solid #d9d9d9;
      }
      &:focus {
        outline: none;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
  .submit-btn-wrapper {
    a {
      text-decoration: none;
    }
    .submit-btn {
      width: 90px;
      height: 36px;
      background-color: #27ade1;
      color: #000;
      text-transform: uppercase;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-size: 14px;
      margin: 20px 0;
    }
  }
}
